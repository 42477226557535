import { isValidNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
//datepicker languages
import { English } from 'flatpickr/dist/l10n/default.js';
import { Dutch } from 'flatpickr/dist/l10n/nl.js';
import { French } from 'flatpickr/dist/l10n/fr.js';
import moment from 'moment';

/** Check if object is empty
 * @param {Object} object - Object to check
 * @return {boolean} true/false
 */
export const isEmpty = object => !(object && Object.keys(object).length);

/** Check if item is string
 * @param {string} item - String to check
 * @return {boolean} true/false
 */
export const isString = item => item && typeof item === 'string';

/** Converts seconds to HH:MM:SS
 * @param {number} seconds - Time in seconds to convert
 * @return {string} Time in HH:MM:SS format
 */
export const convertSecondsToTime = seconds => {
  const timePart = num => ('0' + num).slice(-2);
  let secs = seconds;

  if (typeof secs !== 'number' || secs <= 0) {
    return '00:00:00';
  }

  let minutes = Math.floor(secs / 60);
  secs = secs % 60;

  let hours = Math.floor(minutes / 60);
  minutes = minutes % 60;

  return `${timePart(hours)}:${timePart(minutes)}:${timePart(secs)}`;
};

/** Gets the countrycode from a phonenumber
 * @params {string} phonenumber - Phone number in the international format
 * @return {string} countryCode - country code that is 2 characters long.
 */
export const getCountryCodeFromPhoneNumber = phonenumber => {
  if (!phonenumber || typeof phonenumber !== 'string') {
    return 'be';
  }

  const parsedPhoneNumber = parsePhoneNumberFromString(phonenumber);
  if (!parsedPhoneNumber || !isValidNumber(parsedPhoneNumber)) {
    return 'be';
  }

  return parsedPhoneNumber.countryCode;
};

/** Converts HH:MM:SS to seconds
 * @param {string} time - Time in HH:MM:SS format to convert
 * @return {number} seconds - Time in seconds
 */
export const convertTimeToSeconds = time => {
  // If the time param is e.g. '00:00' convert it to '00:00:00'
  if (/^[0-9]{1,2}:[0-9]{2}$/.test(time)) time = `${time}:00`;

  // Check if the time param is valid
  if (!/^[0-9]{1,2}:[0-9]{2}:[0-9]{2}$/.test(time)) return NaN;

  const timeArray = time.split(':').reverse();
  let seconds = 0;

  timeArray.forEach((item, index) => {
    seconds += parseInt(item, 10) * Math.pow(60, index);
  });

  return seconds;
};

/** Converts MM:SS to seconds
 * @param {string} time - Time in HH:MM:SS format to convert
 * @return {number} seconds - Time in seconds
 */
export const convertTimeFormatFromSeconds = seconds => {
  return moment()
    .startOf('day')
    .seconds(seconds)
    .format('mm:ss');
};

/** Converts phone number to international format
 * @param {string} number - Phone number to convert
 * @return {string} number - Formatted number
 */
export const formatPhoneNumber = number => {
  if (number && !isNaN(number)) {
    if (number[0] !== '+') {
      number = `+${number}`;
    }

    if (isValidNumber(number)) {
      number = parsePhoneNumberFromString(number);
      number = number.format('INTERNATIONAL');
    }

    return number;
  }
  return number;
};

/** Copy text to clipboard
 * @param {string} textToCopy - Text to copy
 */
export const copyToClipboard = textToCopy => {
  const textContainer = document.createElement('input');

  document.body.appendChild(textContainer);
  textContainer.setAttribute('value', textToCopy);
  textContainer.select();
  document.execCommand('copy');
  document.body.removeChild(textContainer);
};

export const flattenDashboardSettings = settings => {
  const settingsFlat = {};

  settings.forEach(container => {
    container.children.forEach(child => {
      settingsFlat[child.name] = true;
    });
  });

  return settingsFlat;
};

/** Construct the options for the flatpickr (date picker) including language, minimun date and starting day
 * @param {string} lang - The selected language. Import new languages with the full name that you can find under \node_moduls\flatpickr\dist\l10n\index.js
 * @param {Date} minDate - The minimum day that you can select
 * @param {int} startingDay - The starting day of the week where 0 = Sunday, 1 = Monday, ... , 6 = Saturday
 */
export const constructFlatpickrSettings = (
  lang,
  minDate = null,
  startingDay = 1
) => {
  let options = {
    minDate: minDate,
    enableTime: true,
    dateFromat: 'Y-m-d H:i',
    time_24hr: true,
    defaultHour: '0'
  };
  switch (lang) {
    case 'nl':
      options.locale = {
        ...Dutch,
        firstDayOfWeek: startingDay
      };
      break;
    case 'fr':
      options.locale = {
        ...French,
        firstDayOfWeek: startingDay
      };
      break;
    default:
      options.locale = {
        ...English,
        firstDayOfWeek: startingDay
      };
      break;
  }
  return options;
};
